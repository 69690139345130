import { css } from '@emotion/react';
import { memo, useEffect, useMemo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import StockKbarBoxView from './component/stockbarBoxView';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { proxy, useSnapshot } from 'valtio';
import { staticStore } from '../heineken_template/_private/staticStore';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { useMount } from 'react-use';
import { useSortSignalrDataStore, useSortSignalrDataStore2, useSortSignalrDataStore3, } from '~/modules/symbolQuote/simple/useSortStore';
import { useStockWeightResource } from '~/modules/chips/useChipResource';
import { SortArrow } from '../edwin/component/content/SortArrow';
import { VideoContent, freemanStore } from './component/VideoContnet';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { sortBy } from 'lodash';
const highPriceSymbol = [
    '3661',
    '5274',
    '3529',
    '3008',
    '6669',
    '3443',
    '6409',
    '5269',
    '1590',
    '4966',
    '4763',
    '6643',
    '2454',
    '3533',
    '2059',
    '6515',
    '3293',
    '6472',
    '6781',
    '2207',
    '3653',
    '1476',
    '2327',
    '2330',
    '2345',
    '8454',
    '5904',
    '6510',
    '6488',
    '6732',
];
export const useWeightDataStore = proxy({
    sortKey: 'none',
    orderKey: 'desc',
});
export const SwitchSortButton = memo(function SwitchSortButton(props) {
    const sortStore = props.sortStore;
    const sortKey = props.sortKey;
    const orderKeyState = useSnapshot(sortStore).orderKey;
    const sortKeyState = useSnapshot(sortStore).sortKey;
    const selected = sortKeyState === sortKey;
    return (<div css={css `
        ${fill_horizontal_all_center};
        width: auto;
        cursor: pointer;
        font-size: 12px;
        align-items: end;
        color: ${selected ? '#ffaf00' : '#efefef'};
      `} onClick={() => {
            sortStore.sortKey = sortKey;
            sortStore.orderKey = orderKeyState === 'asc' ? 'desc' : 'asc';
            props.onClick?.();
        }}>
      <span>{props.children}</span>
      <span css={css `
          margin-bottom: 4px;
        `}>
        <SortArrow sortKey={sortKey} sotre={sortStore}/>
      </span>
    </div>);
});
export const Freeman_SidePane1 = memo(function Freeman_SidePane1() {
    const state = useSnapshot(staticStore);
    const sort2State = useSnapshot(useSortSignalrDataStore2);
    //熱門股--------------
    const popularData = usePopularPick({
        date: state.tradedDate.intraday.format('YYYY-MM-DD'),
        sort: 'value',
        limit: 30,
    });
    const popularSymbol = popularData.resultSymbol ?? [''];
    useEffect(() => {
        signalrStore2.addQuote(popularSymbol);
        return () => {
            signalrStore2.removeQuote(popularSymbol);
        };
    }, [JSON.stringify(popularSymbol)]);
    //權值股--------------
    const twseWeight = useStockWeightResource('TWSE');
    const weightState = useSnapshot(useWeightDataStore);
    const tw50Stock = state.symbol.tw50;
    const tw50Data = useMemo(() => {
        return twseWeight
            .filter(([k]) => tw50Stock?.indexOf(k) !== -1)
            .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
            .sort((a, b) => b.percentage - a.percentage);
    }, [tw50Stock, twseWeight]).slice(0, 30);
    const tw50SortData = () => {
        if (weightState.sortKey === 'ratio' && weightState.orderKey === 'asc') {
            return sortBy(tw50Data, datum => datum.percentage).reverse();
        }
        if (weightState.sortKey === 'ratio' && weightState.orderKey === 'desc') {
            return sortBy(tw50Data, datum => datum.percentage);
        }
        return tw50Data;
    };
    const tw50Symbol = tw50SortData().map(data => data.symbol) ?? [''];
    useEffect(() => {
        signalrStore2.addQuote(tw50Symbol);
        return () => {
            signalrStore2.removeQuote(tw50Symbol);
        };
    }, [JSON.stringify(tw50Symbol)]);
    //高價股--------------
    useEffect(() => {
        signalrStore2.addQuote(highPriceSymbol);
        return () => {
            signalrStore2.removeQuote(highPriceSymbol);
        };
    }, [JSON.stringify(highPriceSymbol)]);
    //--------------
    const popularValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(popularSymbol, 1));
    const tw50Value = signalrHooks2.useQuotes(useSignalr2QuoteSort(tw50Symbol, 2));
    const highPriceValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(highPriceSymbol, 3));
    freemanStore.popularSymbols = popularValue;
    freemanStore.tw50Symbols = tw50Value;
    freemanStore.highPriceSymbols = highPriceValue;
    useMount(() => {
        useSortSignalrDataStore.sortKey = 'symbol';
        useSortSignalrDataStore2.sortKey = 'none';
        useWeightDataStore.sortKey = 'ratio';
        useSortSignalrDataStore3.sortKey = 'close';
    });
    return (<div css={classes.container}>
      <div css={classes.youtubeContainer}>
        <VideoContent />
      </div>

      <div css={classes.kbarContainer}>
        <div css={classes.titleContent}>
          <span css={classes.title}>熱門股</span>
          <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='symbol'>
            代號
          </SwitchSortButton>
          <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='amount'>
            成交金額
          </SwitchSortButton>
          <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='changePrecent'>
            漲跌幅
          </SwitchSortButton>
        </div>
        <div css={classes.kbarContnet}>
          <StockKbarBoxView quote={popularValue.slice(0, 30)}/>
        </div>

        <div css={classes.titleContent}>
          <span css={classes.title}>權值股</span>
          <SwitchSortButton sortStore={useSortSignalrDataStore2} sortKey='symbol'>
            代號
          </SwitchSortButton>
          <SwitchSortButton sortStore={useSortSignalrDataStore2} sortKey='amount' onClick={() => (useWeightDataStore.sortKey = 'none')}>
            成交金額
          </SwitchSortButton>
          <SwitchSortButton sortStore={useSortSignalrDataStore2} sortKey='changePrecent' onClick={() => (useWeightDataStore.sortKey = 'none')}>
            漲跌幅
          </SwitchSortButton>
          <SwitchSortButton sortStore={useWeightDataStore} sortKey='ratio' onClick={() => (useSortSignalrDataStore2.sortKey = 'none')}>
            佔大盤比重
          </SwitchSortButton>
        </div>
        <div css={classes.kbarContnet}>
          <StockKbarBoxView quote={tw50Value.slice(0, 30)}/>
        </div>

        <div css={classes.titleContent}>
          <span css={classes.title}>高價股</span>
          <SwitchSortButton sortStore={useSortSignalrDataStore3} sortKey='symbol'>
            代號
          </SwitchSortButton>
          <SwitchSortButton sortStore={useSortSignalrDataStore3} sortKey='amount'>
            成交金額
          </SwitchSortButton>
          <SwitchSortButton sortStore={useSortSignalrDataStore3} sortKey='changePrecent'>
            漲跌幅
          </SwitchSortButton>
          <SwitchSortButton sortStore={useSortSignalrDataStore3} sortKey='close'>
            收盤價
          </SwitchSortButton>
        </div>
        <div css={classes.kbarContnet}>
          <StockKbarBoxView quote={highPriceValue}/>
        </div>
      </div>
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    background-color: #151515;
    border-radius: 4px;
    user-select: none;
  `,
    youtubeContainer: css `
    width: 100%;
    height: 236px;
    resize: vertical;
    max-height: 236px;
    min-height: 172px;
    ${scrollbar2Css};
    overflow-y: hidden;
    overflow-x: hidden;
  `,
    kbarContainer: css `
    ${fill_vertical_cross_center};
    height: calc(100% - 252px);
    ${scrollbar2Css};
    gap: 2px;
  `,
    titleContent: css `
    ${fill_horizontal_cross_center};
    height: 24px;
    font-weight: bold;
    gap: 6px;
    align-items: end;
    flex-shrink: 0;
  `,
    title: css `
    height: 20px;
    font-size: 14px;
    background-color: #545454;
    border-radius: 4px;
    padding: 0 8px;
  `,
    kbarContnet: css `
    height: 100%;
    min-height: 156px;
    width: 100%;
    background-color: #191919;
    border-radius: 4px;
    border: 1px solid #888888;
  `,
};
